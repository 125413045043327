import React from 'react'

import { Link } from 'gatsby'
import './PortfolioTeaser.css'
import ImgRefL1 from '../../images/ref-l-1@2x.jpg'
import ImgRefR1 from '../../images/ref-r-1@2x.jpg'
import ImgRefR3 from '../../images/ref-r-3@2x.jpg'

const PortfolioTeaser = (props) => {

  return (
        <div class='container xs:p-4 md:p-0 mx-auto h-auto'>
            <div class='column'>
                <div class='project-cell'>
                        <div id='l1' class='wrapper-img-grid img-l-1'>
                            <img src={ImgRefL1} class='img-grid' />
                            <span class='ref-label'>omnium: Smartphone-App für das neue Jobportal</span>
                        </div>
                </div> 
                <div class='project-cell'>
                </div> 
            </div>
            <div class="column">
                <div class="project-cell">
                        <div id="r1" class="wrapper-img-grid img-r-1">
                            <img src={ImgRefR1} class="img-grid" />
                            <span class="ref-label">Archipinion: Design und Entwicklung der Videoplattform für Architek_innen</span>
                        </div>
                </div>
                <div class="project-cell">
                        <div id="r2" class="wrapper-img-grid img-r-2">
                            <img src={ImgRefR3} class="img-grid" />
                            <span class="ref-label">HABA Digitalwerkstatt: Design und Entwicklung der neuen Onlineplattform</span>
                        </div>
                </div>
            </div>
        </div>
  )
}

export default PortfolioTeaser
