import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import './ServicesOverview.css'
import IconServiceConsulting from '../../images/svgs/icon_beratung.svg'
import IconServicePrototyping from '../../images/svgs/icon_prototyping.svg'
import IconServiceDesign from '../../images/svgs/icon_designuxui.svg'
import IconServiceDev from '../../images/svgs/icon_programmierung.svg'
import ImgServiceBackgroundLeft from '../../images/svgs/leistungen-ellipse-gelb.svg'
import ImgServiceBackgroundLeftMobile from '../../images/svgs/leistungen-ellipse-gelb-2.svg'
import ImgServiceBackgroundRight from '../../images/leistungen-ellipse-linien.png'

const ServiceOverview = (props) => {
        const data = useStaticQuery(graphql`
            query ServicesQuery {
                contentfulServiceOverview(slug: {eq: "servicesoverview"}) {
                shortNoService1
                shortNoService2
                shortNoService3
                shortService1
                shortService2
                shortService3
                shortService4
                slug
                titleNoService1
                titleNoService2
                titleNoService3
                titleNoServiceSection
                titleService2
                titleService1
                titleService4
                titleService3
                titleServiceSection
                titleServiceWindow
                }
            }
            `)
        return (
    <div class='leistungen w-full flex'>
     <div class='fenster mx-auto'>
            <div class='fenster_head text-center'>
                <div class="xs:hidden sm:block windowdot"></div>
                <div class="xs:hidden sm:block windowdot"></div>
                <div class="xs:hidden sm:block windowdot clearfix"></div>
                <span class="mx-auto block mt-1">{data.contentfulServiceOverview.titleServiceWindow}</span>
            </div>
            <div class='flex flex-wrap pt-0 sm:px-0 md:px-16 mdlg:px-24 lg:px-4 xl:px-12 '>
                <div class='w-full lg:w-7/12'>
                    <div class='w-full xs:text-center mt-2 mb-2'><h3>{data.contentfulServiceOverview.titleServiceSection}</h3></div>
                    <div class='flex flex-wrap leistungen-swenden xl:mt-8'>
                        <div class='w-full text-center md:w-1/2 md:text-left xs:pt-0 lg:pt-4 lg:pb-0 p-6'>
                            <div class='icon-wrapper flex items-center justify-center md:justify-start'>
                                <IconServiceConsulting/>
                            </div>
                                <span class='title-leistungen'>{data.contentfulServiceOverview.titleService1}</span>
                            <p class='text-leistungen'>{data.contentfulServiceOverview.shortService1}</p>
                        </div>
                        <div class='w-full text-center md:w-1/2 md:text-left lg:pt-4 lg:pb-0 p-6'>
                            <div class='icon-wrapper flex items-center justify-center md:justify-start'>
                                <IconServicePrototyping/>
                            </div>
                            <span class='title-leistungen'>{data.contentfulServiceOverview.titleService2}</span>
                            <p class='text-leistungen'>{data.contentfulServiceOverview.shortService2}</p></div>
                        <div class='w-full text-center md:w-1/2 md:text-left lg:pt-0 p-6'>
                            <div class='icon-wrapper flex items-center justify-center md:justify-start'>
                                <IconServiceDesign/>
                            </div>
                            <span class='title-leistungen'>{data.contentfulServiceOverview.titleService3}</span>
                            <p class='text-leistungen'>{data.contentfulServiceOverview.shortService3}</p>
                        </div>
                        <div class='w-full text-center md:w-1/2 md:text-left lg:pt-0 p-6'>
                            <div class='icon-wrapper flex items-center justify-center md:justify-start'>
                                <IconServiceDev/>
                            </div>
                            <span class='title-leistungen'>{data.contentfulServiceOverview.titleService4}</span>
                            <p class='text-leistungen'>{data.contentfulServiceOverview.shortService4}</p>
                        </div>
                    </div>
                </div>
                <div class='hidden lg:flex lg:w-1/12'>
                </div>
                <div class='w-full lg:w-4/12 xs:p-4 lg:p-12 pt-24 xs:mt-16 sm:mt-8 md:mt-32 lg:mt-0 col-leistungen-noswenden'>
                    <div class='flex flex-wrap leistungen-noswenden'>
                        <div class='w-full xs:text-center lg:text-left mb-4 xl:mb-8'>{data.contentfulServiceOverview.titleNoServiceSection}</div>
                        <div class='w-full md:w-1/3 lg:w-full xs:text-center lg:text-left'>
                            <span class='title-leistungen'>{data.contentfulServiceOverview.titleNoService1}</span>
                            <p class='text-leistungen'>{data.contentfulServiceOverview.shortNoService1}</p>
                        </div>
                        <div class='w-full md:w-1/3 lg:w-full xs:text-center lg:text-left'>
                            <span class='title-leistungen'>{data.contentfulServiceOverview.titleNoService2}</span>
                            <p class='text-leistungen'>{data.contentfulServiceOverview.shortNoService2}</p>
                        </div>
                        <div class='w-full md:w-1/3 lg:w-full xs:text-center lg:text-left'>
                            <span class='title-leistungen'>{data.contentfulServiceOverview.titleNoService3}</span>
                            <p class='text-leistungen'>{data.contentfulServiceOverview.shortNoService3}</p>
                        </div>
                    </div>
                </div>
            </div>
            
    </div>
    <div class='fenster-background-left mx-auto'>
        <ImgServiceBackgroundLeft/>
    </div>
    <div class='fenster-background-right mx-auto'>
    <img src={ImgServiceBackgroundRight} alt="Logo" />
    </div>
</div>
  )
}

export default ServiceOverview

