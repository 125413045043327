import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import './ContactBoxFullWidth.css'
import Button from '../Button/Button'

const ContactBoxFullWidth = (props) => {
  let options = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => <h2 className='mb-8 mt-4'>{children}</h2>,
      [BLOCKS.HEADING_3]: (node, children) => <h3 className='mb-6 mt-3'>{children}</h3>,
      [BLOCKS.PARAGRAPH]: (node, children) => <p className='mb-6'>{children}</p>
    },
    renderText: text =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br />, text])
  }
  const data = useStaticQuery(graphql`
    query ContactBox {
      contentfulDarkCtaBox (slug: {eq: "contactbox-fullwidth"}) {
        labelButton1
        labelButton2
        titleContactBox
        childContentfulDarkCtaBoxShortContactBoxRichTextNode {
          json
        }
      }
    }
  `)
 
  return (
    <div className='flex w-full max-w-6xl flex-wrap contactbox p-8 lg:p-12'>
        <div className='flex flex-col md:w-6/12 max-w-4xl '> 
            <p className='text-white mb-0'>{documentToReactComponents(data.contentfulDarkCtaBox.childContentfulDarkCtaBoxShortContactBoxRichTextNode.json)}</p>
        </div>
        <div className='flex flex-col md:w-6/12 max-w-4xl items-end'>
              <Button to='/projekt-starten' withArrow className='p-4 mr-8 xs:mt-8 md:mt-0 btn-white-outline btn-filled'>{data.contentfulDarkCtaBox.labelButton1}</Button>
        </div>
    </div>
  )
}

export default ContactBoxFullWidth
